import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";
import _unionBy from "lodash/unionBy";

const billingListSlice = createSlice({
  name: "billingList",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    totalNewOrders: 0,
    selectedRowKeys: [],
    selectedRows: [],
    detail: {},
    billDetail: {},
    productData: [],
    productCreateData: [],
    productModalData: {},
    discountDirectModalData: {},
    productsKey: [],
    isAccessoryModal: false,
    accessoryData: [],
  },
  reducers: {
    getBillingListRequest(state) {
      return { ...state, isLoading: true };
    },
    getBillingListSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs, totalNewOrders } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
        totalNewOrders,
      };
    },
    getBillDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getBillDetailSuccess(state, action) {
      const { payload = {} } = action;
      const { products } = payload;
      return {
        ...state,
        isLoading: false,
        billDetail: payload,
        productData: products,
      };
    },
    setSelectedBusinessIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    updateBillStatusRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateBillStatusSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setBillProductModal(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        productModalData: {
          visible,
          data,
        },
      };
    },
    setBillAccessoryModal(state, action) {
      const { payload = {} } = action;
      const { visible } = payload;
      return {
        ...state,
        isAccessoryModal: visible,
      };
    },
    setDiscountDirectModal(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        discountDirectModalData: {
          visible,
          data,
        },
      };
    },
    createBillRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createBillSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateBillRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateBillSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    addProductDataEdit(state, action) {
      const { payload = {} } = action;
      const { data, key } = payload;
      return {
        ...state,
        isLoading: false,
        productCreateData: data,
        productsKey: key,
      };
    },
    updateProductAmount(state, action) {
      const { productId, amount } = action.payload;
      return {
        ...state,
        productCreateData: state.productCreateData.map((product) =>
          product._id === productId
            ? { ...product, amount: Number(amount) }
            : product
        ),
      };
    },

    updateAccessoryAmount(state, action) {
      const { accessoryId, amount } = action.payload;
      return {
        ...state,
        accessoryData: state.accessoryData.map((accessory) =>
          accessory._id === accessoryId
            ? { ...accessory, amount: Number(amount) }
            : accessory
        ),
      };
    },
    addProductToBill(state, action) {
      const { payload = {} } = action;
      const { data, key } = payload;

      if (!data || !key) {
        return state;
      }

      return {
        ...state,
        productCreateData: _unionBy(
          state.productCreateData,
          data
            .filter((item) => key.includes(item._id))
            .map((item) => ({
              ...item,
              amount: Number(item.amount || 1), // Đảm bảo amount là số
            })),
          "_id"
        ),
        productsKey: _unionBy(state.productsKey, key),
      };
    },
    addAccessoryToBill(state, action) {
      const { payload = {} } = action;
      const { data, key } = payload;

      if (!data || !key) {
        return state;
      }

      return {
        ...state,
        accessoryData:
          key.length > 0
            ? _unionBy(
                state.accessoryData,
                data
                  .filter((item) => key.includes(item._id))
                  .map((item) => ({
                    ...item,
                    amount: Number(item.amount || 1), // Đảm bảo amount là số
                  })),
                "_id"
              )
            : data.map((item) => ({
                ...item,
                amount: Number(item.amount || 1),
              })),
      };
    },
    removeProductBill(state, action) {
      const { payload = {} } = action;
      const { id } = payload;
      return {
        ...state,
        productCreateData: state.productCreateData.filter(
          (item) => item._id !== id
        ),
        productsKey: state.productsKey.filter((item) => item !== id),
        selectedRowKeys: state.productsKey.filter((item) => item !== id),
      };
    },
    removeAccessoryBill(state, action) {
      const { payload = {} } = action;
      const { id } = payload;
      return {
        ...state,
        accessoryData: state.accessoryData.filter((item) => item._id !== id),
        selectedRowKeys: state.productsKey.filter((item) => item !== id),
      };
    },
    resetCreateBillState(state) {
      return {
        ...state,
        billDetail: {},
        productData: [],
        productCreateData: [],
        productModalData: {},
        productsKey: [],
        selectedRowKeys: [],
        selectedRows: [],
        accessoryData: [],
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isSettingTag: false };
    },
  },
});

export const {
  resetCreateBillState,
  setDiscountDirectModal,
  updateBillRequest,
  updateBillSuccess,
  addProductDataEdit,
  removeProductBill,
  addProductToBill,
  updateProductAmount,
  updateAccessoryAmount,
  switchBillModal,
  setBillProductModal,
  createBillRequest,
  createBillSuccess,
  getBillDetailRequest,
  getBillDetailSuccess,
  setSelectedBusinessIds,
  updateBillStatusRequest,
  getBillingListRequest,
  getBillingListSuccess,
  updateBillStatusSuccess,
  stopLoading,
  setBillAccessoryModal,
  addAccessoryToBill,
  removeAccessoryBill,
} = billingListSlice.actions;

export default billingListSlice.reducer;
