import {
  Button,
  Col,
  List,
  Row,
  Typography,
  Image,
  Carousel,
  InputNumber,
} from "antd";
import Lightbox from "react-image-lightbox";
import BackButton from "components/BackButton";
import BillCreateForm from "components/Bill/CreateForm";
import i18n from "i18n";
import {
  createBillRequest,
  removeProductBill,
  setBillProductModal,
  addProductDataEdit,
  getBillDetailRequest,
  updateBillRequest,
  resetCreateBillState,
  setBillAccessoryModal,
  removeAccessoryBill,
  addAccessoryToBill,
} from "providers/BillingProvider/slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import "./styles.less";
import moment from "moment";
import ProductCreateBillModal from "components/Bill/ProductCreateBillModal";
import Helper from "utils/helpers";
import { TIME_FRAME_TYPE } from "utils/constants";
import { PlaceHolderIMG } from "assets/images";
import AccessoryCreateBillModal from "components/Bill/AccessoryCreateBillModal";

const CreateBill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setOpen] = useState("");
  const params = useParams();
  const idBill = params.idBills ?? "";
  const billDetail = useSelector((state) =>
    _get(state, "billingList.billDetail")
  );
  const productData = useSelector((state) =>
    _get(state, "billingList.productData")
  );
  const productsKey = productData.map((item) => item?._id);
  const isLoading = useSelector((state) =>
    _get(state, "billingList.isLoading")
  );
  const productCreateData = useSelector((state) =>
    _get(state, "billingList.productCreateData")
  );

  const accessoryData = useSelector((state) =>
    _get(state, "billingList.accessoryData")
  );
  const createInitialValues = {
    transactionType: "",
    name: "",
    postCode: "",
    timeFrame: "",
    orderFrom: "",
    address: "",
    phone: "",
    currency: "",
    promotion: 0,
    percentagePromotion: 0,
    coordinates: [0, 0],
    shippingFee: 0,
    orderIdCustom: "",
    expectedDeliveryDate: "",
  };
  const initialValues = {
    transactionType: billDetail.transactionType ?? "",
    name: billDetail.name ?? "",
    postCode: billDetail.postCode ?? "",
    timeFrame: billDetail.timeFrame ?? "",
    orderFrom: billDetail.orderFrom ?? "",
    address: billDetail.address ?? "",
    phone: billDetail.phone ?? "",
    currency: billDetail.currency ?? "",
    promotion: 0,
    percentagePromotion: 0,
    coordinates: billDetail.coordinates ?? [0, 0],
    shippingFee: billDetail.shippingFee ?? "",
    orderIdCustom: billDetail.orderIdCustom ?? "",
    expectedDeliveryDate: billDetail.expectedDeliveryDate ?? "",
    imageAddress: billDetail.imageAddress,
  };

  useEffect(() => {
    if (idBill !== "") {
      dispatch(getBillDetailRequest({ orderId: idBill }));
    }
  }, [dispatch, idBill]);
  useEffect(() => {
    if (idBill !== "") {
      dispatch(addProductDataEdit({ data: productData, key: productsKey }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (billDetail?.accessories?.length > 0) {
      dispatch(addAccessoryToBill({ key: [], data: billDetail?.accessories }));
    }
  }, []);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(resetCreateBillState());
    };
  }, []);

  const handleCreateBill = async (values) => {
    const _params = values;
    const latLng = await Helper.getLatLng(_params.address, {
      lng: _params.lng,
      lat: _params.lat,
    });
    if (_params.timeFrame.length <= 5) {
      _params.timeFrame = await TIME_FRAME_TYPE[values.timeFrame];
    }

    _params.coordinates = [latLng.lng, latLng.lat];
    _params.products = productCreateData.map((item) => {
      const obj = {
        id: item._id,
        amount: item.amount,
      };
      return obj;
    });
    // _params.products = productsCreateKey;
    _params.imageAddress = billDetail.imageAddress;
    console.log(_params, "_params");
    dispatch(
      idBill
        ? updateBillRequest({ ..._params, orderId: idBill })
        : createBillRequest(_params)
    ).then(() => {
      dispatch(resetCreateBillState());
      navigate(`/fonehouse/billing_list/${idBill}`, {
        state: { canGoBack: true },
        replace: true,
      });
    });
  };
  const handleRemoveProduct = (id) => {
    dispatch(removeProductBill({ id }));
  };
  const handleRemoveAccessory = (id) => {
    dispatch(removeAccessoryBill({ id }));
  };
  const handleOpenModal = () => {
    dispatch(
      setBillProductModal({
        visible: true,
      })
    );
  };
  const handleOpenAccessoryModal = () => {
    dispatch(
      setBillAccessoryModal({
        visible: true,
      })
    );
  };
  const renderProductItem = (item) => (
    <Row style={{ marginTop: 20, marginBottom: 20 }}>
      <Row gutter={16}>
        <Col>
          <Carousel autoplay style={{ width: 150, height: 150 }} arrows>
            {(item?.images || []).map((image, idx) => (
              <Image
                onClick={() => {
                  setOpen(true);
                  setPhotoIndex(idx);
                }}
                fallback={PlaceHolderIMG}
                key={image}
                width={150}
                height={150}
                src={image}
              />
            ))}
          </Carousel>
          {isOpen === item?._id && !_isEmpty(item?.images) && (
            <Lightbox
              mainSrc={item?.images[photoIndex]}
              nextSrc={item?.images[(photoIndex + 1) % item?.images.length]}
              prevSrc={
                item?.images[
                  (photoIndex + item?.images.length - 1) % item?.images.length
                ]
              }
              onCloseRequest={() => setOpen(false)}
              onMovePrevRequest={() => {
                setPhotoIndex(
                  (photoIndex + item?.images.length - 1) % item?.images.length
                );
              }}
              onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % item?.images.length);
              }}
            />
          )}
        </Col>
        <Col span={10}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.productCode")}:
            </div>
            <div className="text-sub-content">{item?._id}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.imei")}:
            </div>
            <div className="text-sub-content">{item?.imei}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.softwareVersion")}:
            </div>
            <div className="text-sub-content">{item?.softwareVersion}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.accessories")}:
            </div>
            <div className="text-sub-content">{item?.accessories}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.amount")}:
            </div>
            <InputNumber
              min={1}
              value={item?.amount || 1} // Thay defaultValue bằng value
              onChange={(value) => {
                dispatch({
                  type: "billingList/updateProductAmount",
                  payload: {
                    productId: item._id,
                    amount: value || 1,
                  },
                });
              }}
              style={{ width: 80, marginLeft: 8 }}
            />
          </Row>
          <Row align="middle" style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.priceSale")}:
            </div>
            <div className="text-product-price">
              {item?.promotionPrice} {item?.currency}
            </div>
          </Row>
        </Col>
        <div
          style={{
            height: "100%",
            width: 1,
            backgroundColor: "#909090",
            marginLeft: 5,
            marginRight: 5,
          }}
        ></div>
        <Col span={6}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.createAt")}:
            </div>
            <div className="text-sub-content">
              {moment(item?.createdAt).format("DD/MM/YYYY")}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.manufacture")}:
            </div>
            <div className="text-sub-content">{item?.manufacture}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.type")}:
            </div>
            <div className="text-sub-content">{item?.state}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.memory")}:
            </div>
            <div className="text-sub-content">{item?.ram}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.inputPrice")}:
            </div>
            <div className="text-input-price">
              {item?.inputPrice?.toLocaleString()} {item.currency}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.initialPrice")}:
            </div>
            <div className="text-input-price">
              {item?.price?.toLocaleString()} {item.currency}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.extraPrice")}:
            </div>
            <div
              style={{
                color:
                  item?.promotionPrice - item?.inputPrice < 0 ? "red" : "green",
              }}
              className="text-extra-price"
            >
              {(item?.promotionPrice - item?.inputPrice)?.toLocaleString()}{" "}
              {item.currency}
            </div>
          </Row>
        </Col>
      </Row>
      <Button
        style={{ marginTop: 10, marginBottom: 10 }}
        onClick={() => handleRemoveProduct(item?._id)}
        danger
        size="small"
        color="primary"
      >
        {i18n.t("createBill.remove")}
      </Button>
    </Row>
  );

  const renderAccessoryItem = (item) => (
    <Row style={{ marginTop: 20, marginBottom: 20 }}>
      <Row gutter={16}>
        <Col>
          <Carousel autoplay style={{ width: 150, height: 150 }} arrows>
            {(item?.images || []).map((image, idx) => (
              <Image
                onClick={() => {
                  setOpen(true);
                  setPhotoIndex(idx);
                }}
                fallback={PlaceHolderIMG}
                key={image}
                width={150}
                height={150}
                src={image}
              />
            ))}
          </Carousel>
          {isOpen === item?._id && !_isEmpty(item?.images) && (
            <Lightbox
              mainSrc={item?.images[photoIndex]}
              nextSrc={item?.images[(photoIndex + 1) % item?.images.length]}
              prevSrc={
                item?.images[
                  (photoIndex + item?.images.length - 1) % item?.images.length
                ]
              }
              onCloseRequest={() => setOpen(false)}
              onMovePrevRequest={() => {
                setPhotoIndex(
                  (photoIndex + item?.images.length - 1) % item?.images.length
                );
              }}
              onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % item?.images.length);
              }}
            />
          )}
        </Col>
        <Col span={10}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.productCode")}:
            </div>
            <div className="text-sub-content">{item?._id}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("createBill.accessoryName")}:
            </div>
            <div className="text-sub-content">{item?.name}</div>
          </Row>
          <Row style={{ padding: "5px 0px", alignItems: "center" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.amount")}:
            </div>
            <InputNumber
              min={1}
              value={item?.amount || 1} // Thay defaultValue bằng value
              onChange={(value) => {
                dispatch({
                  type: "billingList/updateAccessoryAmount",
                  payload: {
                    accessoryId: item._id,
                    amount: value || 1,
                  },
                });
              }}
              style={{ width: 80, marginLeft: 8 }}
            />
          </Row>
        </Col>
        <div
          style={{
            height: "100%",
            width: 1,
            backgroundColor: "#909090",
            marginLeft: 5,
            marginRight: 5,
          }}
        ></div>
        <Col span={6}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.createAt")}:
            </div>
            <div className="text-sub-content">
              {moment(item?.createdAt).format("DD/MM/YYYY")}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.inputPrice")}:
            </div>
            <div className="text-input-price">
              {item?.initialPrice.toLocaleString()} JPY
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.initialPrice")}:
            </div>
            <div className="text-input-price">
              {item?.price?.toLocaleString()} JPY
            </div>
          </Row>
        </Col>
      </Row>
      <Button
        style={{ marginTop: 10, marginBottom: 10 }}
        onClick={() => handleRemoveAccessory(item?._id)}
        danger
        size="small"
        color="primary"
      >
        {i18n.t("createBill.remove")}
      </Button>
    </Row>
  );

  return (
    <div className="bill-create-wrapper">
      <ProductCreateBillModal setBillProductModal={setBillProductModal} />
      <AccessoryCreateBillModal />
      <Row
        className="bill-create-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>
            {idBill
              ? i18n.t("createBill.titleEdit")
              : i18n.t("createBill.title")}
          </Typography.Title>
        </Row>
      </Row>
      <Row>
        <Col span={10}>
          <div className="create-wrapper">
            <BillCreateForm
              onSubmit={(values) => handleCreateBill(values)}
              loading={isLoading}
              initialValues={!idBill ? createInitialValues : initialValues}
            />
          </div>
        </Col>
        <Col span={14}>
          <div className="create-wrapper">
            <Row justify="space-between">
              <div className="text-title">
                {i18n.t("createBill.billDetail")}
              </div>
              <Row>
                <Button
                  onClick={() => handleOpenAccessoryModal()}
                  size="small"
                  color="primary"
                  style={{ width: 150 }}
                >
                  {i18n.t("createBill.addAccessories")}
                </Button>
                <Button
                  onClick={() => handleOpenModal()}
                  size="small"
                  color="primary"
                  style={{ width: 150 }}
                >
                  {i18n.t("createBill.add")}
                </Button>
              </Row>
            </Row>
            <div className="text-title">{i18n.t("createBill.productList")}</div>
            <List
              itemLayout="vertical"
              pagination={{
                pageSize: 4,
              }}
              dataSource={productCreateData}
              renderItem={(item) => renderProductItem(item)}
            />
            {accessoryData && accessoryData?.length > 0 && (
              <>
                <div className="text-title">
                  {i18n.t("createBill.accessoryList")}
                </div>
                <List
                  itemLayout="vertical"
                  pagination={{
                    pageSize: 4,
                  }}
                  dataSource={accessoryData}
                  renderItem={(item) => renderAccessoryItem(item)}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CreateBill;
