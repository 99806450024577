import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import DefaultLogo from "assets/images/logovietgrp.svg";
import "./styles.less";
import { Avatar, Image, Row } from "antd";
import Helper from "utils/helpers";
import Gallery from "react-grid-gallery";
import ReactPlayer from "react-player";
import Linkify from "react-linkify";
import { PlaceHolderIMG, StockExist, Removed, SoldOut } from "assets/images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProductTypeRequest } from "providers/ProductProvider/slice";
import _ from "lodash";
import LoadingIndicator from "components/LoadingIndicator";
import OrderMessage from "./OrderMessage";

const MessageCell = ({ data = {}, isOwnCell }) => {
  const dispatch = useDispatch();
  const name = useSelector((state) => _get(state, "auth.currentUser.name"));
  const avatar = _get(data, "sender.userDetail.avatar") || DefaultLogo;
  const attachments = _get(data, "attachments", []) || [];
  const videos = attachments.filter((att) => att.type === "video");
  const images = attachments.filter((att) => att.type === "image");

  // useEffect(() => {
  //   dispatch(getProductTypeRequest());
  // }, [dispatch]);

  const componentDecorator = (href, text, key) => (
    <a
      className={isOwnCell ? "self-message" : "other-message"}
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  const renderStatusProduct = (status) => {
    switch (status) {
      case "active":
        return <div className="status-content">Còn hàng</div>;
      case "ordered":
        return <div className="status-content">Đã bán</div>;
      default:
        return <div className="status-content">Đã xoá</div>;
    }
  };

  const renderStatusProductIcon = (status) => {
    switch (status) {
      case "active":
        return <Image src={StockExist} />;
      case "ordered":
        return <Image src={SoldOut} />;
      default:
        return <Image src={Removed} />;
    }
  };
  console.log(data.productDetail, "data.productDetail");
  const renderProductItem = () => (
    <Link to={`/fonehouse/products/${_get(data, "productDetail._id")}`}>
      <div
        className="product-container"
        style={{ width: 350, alignSelf: "flex-end" }}
      >
        <Image
          fallback={PlaceHolderIMG}
          src={_get(data, "productDetail.images[0]")}
          preview={false}
        />
        <div className="promotion-box">
          <div className="text-promotion">
            {_get(data, "productDetail.percentagePromotion", 0)}%
          </div>
        </div>
        <Row className={`status-box-${_get(data, "productDetail.status")}`}>
          {renderStatusProductIcon(_get(data, "productDetail.status"))}
          {renderStatusProduct(_get(data, "productDetail.status"))}
        </Row>
        <div style={{ padding: 16 }}>
          <div className="product-name">
            {_get(data, "productDetail.name", "--/--")}
          </div>
          <div className="product-info">
            <b>Bộ nhớ:</b> {_get(data, "productDetail.ram", "--/--")}
          </div>
          <div className="product-info">
            <b>Dòng: </b>
            {_get(data, "productDetail.line", "--/--")}
          </div>
          <div className="product-info">
            <b>Mô tả chi tiết:</b>{" "}
            {_get(data, "productDetail.description", "--/--")}
          </div>
          <div className="product-price">
            円 {data?.productDetail?.promotionPriceJPY?.toLocaleString() ?? 0}
          </div>
        </div>
      </div>
    </Link>
  );

  const renderOrderDetail = () => {
    if (data.orderDetail && Object.keys(data?.orderDetail).length !== 0) {
      return <OrderMessage orderDetail={data.orderDetail} />;
    }
    return <div></div>;
  };
  if (data.type === "product" && _get(data, "productDetail._id")) {
    return (
      <div
        className={`message-cell-container ${
          isOwnCell ? "own-cell-container" : ""
        }`}
      >
        <div className="message-time">
          {Helper.getMessageTimeStamp(data.createdAt)}
        </div>

        <div className={`flex ${isOwnCell ? "flex-end" : ""}`}>
          {!isOwnCell && <Avatar size={32} src={avatar} className="avatar" />}
          <div
            style={{
              flexDirection: "column",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            {data.text && (
              <div className="message-text-container">
                <Linkify componentDecorator={componentDecorator}>
                  {data.text}
                </Linkify>
              </div>
            )}

            {!_isEmpty(videos) && (
              <div className="video-container">
                {videos.map((video, index) => (
                  <ReactPlayer
                    style={{ marginLeft: "auto" }}
                    controls
                    key={index}
                    url={_get(video, "data.videoUrl")}
                    width={200}
                    height="100%"
                  />
                ))}
              </div>
            )}
            {!_isEmpty(images) && (
              <div
                className="image-container"
                style={{
                  height: "100%",
                  float: "right",
                  display: "block",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Gallery
                  images={images.map((i) => ({
                    src: _get(i, "data.imageUrl"),
                    thumbnail: _get(i, "data.imageUrl"),
                    thumbnailWidth: 1,
                    thumbnailHeight: 1,
                  }))}
                  enableImageSelection={false}
                />
              </div>
            )}

            {data.type === "product" &&
              _get(data, "productDetail._id") &&
              renderProductItem()}
            {isOwnCell ? (
              <div className="send-by-container">
                <div className="send-by">
                  Được gửi từ {data.sender.name ?? name}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  if (data.type !== "product") {
    return (
      <div
        className={`message-cell-container ${
          isOwnCell ? "own-cell-container" : ""
        }`}
      >
        <div className="message-time">
          {Helper.getMessageTimeStamp(data.createdAt)}
        </div>

        <div className={`flex ${isOwnCell ? "flex-end" : ""}`}>
          {!isOwnCell && <Avatar size={32} src={avatar} className="avatar" />}
          <div
            style={{
              flexDirection: "column",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            {data.text && (
              <div className="message-text-container">
                <Linkify componentDecorator={componentDecorator}>
                  {data.text}
                </Linkify>
              </div>
            )}

            {!_isEmpty(videos) && (
              <div className="video-container">
                {videos.map((video, index) => (
                  <ReactPlayer
                    style={{ marginLeft: "auto" }}
                    controls
                    key={index}
                    url={_get(video, "data.videoUrl")}
                    width={200}
                    height="100%"
                  />
                ))}
              </div>
            )}
            {!_isEmpty(images) && (
              <div
                className="image-container"
                style={{
                  height: "100%",
                  float: "right",
                  display: "block",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Gallery
                  images={images.map((i) => ({
                    src: _get(i, "data.imageUrl"),
                    thumbnail: _get(i, "data.imageUrl"),
                    thumbnailWidth: 1,
                    thumbnailHeight: 1,
                  }))}
                  enableImageSelection={false}
                />
              </div>
            )}
            {renderOrderDetail()}
            {data.type === "product" &&
              _get(data, "productDetail._id") &&
              renderProductItem()}
            {isOwnCell ? (
              <div className="send-by-container">
                <div className="send-by">
                  Được gửi từ {data.sender.name ?? name}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
};

MessageCell.propTypes = {
  data: PropTypes.object,
  isOwnCell: PropTypes.bool,
};

export default MessageCell;
