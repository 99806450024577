import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "antd";
import _get from "lodash/get";
import PropTypes from "prop-types";
import "./styles.less";
import i18n from "i18n";
import BackIcon from "assets/images/back.svg";
import { useNavigate } from "react-router-dom";
import { ProductColumn } from "components/Columns";
import Table from "components/common/Table";
import { getProductListRequest } from "providers/ProductProvider/slice";
import { useQuery } from "hooks";
import {
  setSelectedBusinessIds,
  addProductToBill,
  resetCreateBillState,
} from "providers/BillingProvider/slice";
import SearchBar from "./SearchBar";

const ProductCreateBillModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBillProductModal } = props;
  const productModalData = useSelector(
    (state) => state.billingList.productModalData
  );
  const productList = useSelector((state) => _get(state, "product.docs"));
  const ViewedProductList = useSelector((state) =>
    _get(state, "product.ViewedProductList")
  );
  const productTotal = useSelector((state) => _get(state, "product.totalDocs"));
  const isLoading = useSelector((state) => _get(state, "product.isLoading"));
  useQuery(getProductListRequest, { arrayParams: ["type", "status"] });
  const { visible } = productModalData;
  const selectedRowKeys = useSelector((state) =>
    _get(state, "billingList.selectedRowKeys", [])
  );
  useEffect(() => {
    dispatch(resetCreateBillState());
  }, [dispatch]);
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(setSelectedBusinessIds({ record, selected, selectedRows }));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      dispatch(setSelectedBusinessIds({ selected, selectedRows, changeRows }));
    },
  };
  const handleCloseModal = () => {
    dispatch(
      setBillProductModal({
        visible: false,
        data: {},
      })
    );
  };
  const handleAddBillingProduct = () => {
    // Kiểm tra nếu có selectedRowKeys và ViewedProductList
    if (selectedRowKeys?.length > 0 && ViewedProductList?.length > 0) {
      dispatch(
        addProductToBill({
          key: selectedRowKeys,
          data: ViewedProductList,
        })
      );
      handleCloseModal();
    }
  };

  const columns = [
    {
      ...ProductColumn.entryDate,
      width: 130,
    },
    {
      ...ProductColumn.name,
      width: 240,
    },
    ProductColumn.ram,
    ProductColumn.state,
    ProductColumn.imei,
    ProductColumn.type,
    ProductColumn.price,
    ProductColumn.tag,
  ];

  return (
    <div>
      {visible && (
        <Modal
          title={i18n.t("createBill.addProductToBill")}
          visible={visible}
          width={1000}
          className="market-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              type="primary"
              style={{ width: 150, marginRight: 16, marginBottom: 20 }}
              onClick={() => handleAddBillingProduct(selectedRowKeys)}
            >
              Thêm sản phẩm
            </Button>
          </div>
          <SearchBar />
          <Table
            rowSelection={rowSelection}
            scroll={{ x: 1000 }}
            loading={isLoading}
            bordered
            columns={columns}
            dataSource={productList}
            rowKey={(data) => data._id}
            total={productTotal}
            onRow={(record) => ({
              onDoubleClick: () => {
                navigate(`/fonehouse/products/${record._id}`, {
                  state: { canGoBack: true },
                });
              },
            })}
          />
        </Modal>
      )}
    </div>
  );
};
ProductCreateBillModal.propTypes = {
  setBillProductModal: PropTypes.func,
};

export default ProductCreateBillModal;
