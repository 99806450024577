import { put, call, takeEvery, takeLeading, select } from "redux-saga/effects";
import { notification } from "antd";
import request from "utils/api";
import _get from "lodash/get";
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import i18n from "i18n";
import {
  updateBillSuccess,
  updateBillRequest,
  stopLoading,
  getBillingListRequest,
  getBillingListSuccess,
  updateBillStatusSuccess,
  updateBillStatusRequest,
  getBillDetailSuccess,
  getBillDetailRequest,
  createBillSuccess,
  createBillRequest,
} from "./slice";

const UPDATE_BILL_STATUS_TEXT = {
  success: "Đã giao hàng",
  shipped: "Đã gửi hàng",
  cancel: "Hủy đơn hàng",
};

function handleAddAccessoriesToPayload(accessoryData) {
  const accessories = [];
  if (accessoryData?.length > 0) {
    accessoryData.forEach((item) => {
      accessories.push({
        id: item._id,
        amount: item.amount ?? 1,
      });
    });
  }
  return accessories;
}

function* getBillingList(action) {
  try {
    const params = _pick(action.payload, [
      "page",
      "limit",
      "keyword",
      "roles",
      "sortTitle",
      "status",
      "sortName",
      "sortEmail",
      "userId",
    ]);
    if (!_isEmpty(params.keyword)) {
      params.keyword =
        Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
    }
    const transactionType = [];
    if (params?.status) {
      params?.status.forEach((item, index) => {
        if (["Daibiki", "cash", "transfer"].includes(item)) {
          transactionType.push(item);
          params.status.splice(index, 1);
        }
      });
      if (transactionType.length > 0) {
        params.transactionType = transactionType;
      }
    }
    const { data } = yield call(
      request,
      "api/fonehouse/order-manager/orders",
      params,
      { method: "GET" }
    );
    yield put(getBillingListSuccess(data));
  } catch (error) {
    yield put(stopLoading());
  }
}

function* getBillDetail(action) {
  const params = _pick(action.payload, ["orderId"]);
  const { data } = yield call(
    request,
    "api/fonehouse/order-manager/order",
    params,
    { method: "GET" }
  );
  yield put(getBillDetailSuccess(data));
}

function* updateBillStatus(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["orderId", "status"]);
  const response = yield call(
    request,
    "api/fonehouse/order-manager/order-status",
    params,
    { method: "PUT" }
  );

  yield put(updateBillStatusSuccess(response?.data, meta));
  if (response?.deficiencyAmount) {
    Helper.toastr(
      `Người dùng này đang thiếu ${response?.deficiencyAmount} điểm`,
      `Cảnh báo`,
      "error"
    );
  } else {
    Helper.toastr(
      `ID:${params.orderId}`,
      `${UPDATE_BILL_STATUS_TEXT[params.status]} thành công`,
      "success"
    );
  }
}

function* createBill(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "products",
    "transactionType",
    "name",
    "timeFrame",
    "postCode",
    "address",
    "orderFrom",
    "currency",
    "promotion",
    "percentagePromotion",
    "coordinates",
    "orderIdCustom",
    "shippingFee",
    "phone",
    "expectedDeliveryDate",
  ]);
  if (params.products.length === 0) {
    Helper.toastr("Phải có ít nhất 1 sản phẩm trong đơn hàng", "Lỗi!", "error");
    yield put(stopLoading());
  } else {
    const { accessoryData } = yield select((state) => state.billingList);

    if (accessoryData?.length > 0) {
      const accessories = [];
      accessoryData.forEach((item) => {
        accessories.push({
          id: item._id,
          amount: item.amount ?? 1,
        });
      });
      params.accessories = accessories;
    }
    const { data } = yield call(
      request,
      "api/fonehouse/order-manager/create-order-admin",
      params,
      { method: "POST" }
    );
    yield put(createBillSuccess(data, meta));
  }
}

function* createBillError(e) {
  const errors = _get(e, "details") || _get(e, "errors");
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      if (_get(error, "field") === "orderIdCustom") {
        notification.error({
          message: i18n.t("alert.error"),
          description: "Mã bill đã tồn tại",
        });
      } else {
        notification.error({
          message: i18n.t("alert.error"),
          description: _get(error, "message"),
        });
      }
    });
  }
  yield put(stopLoading());
}

function* updateBill(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "orderId",
    "orderIdCustom",
    "products",
    "transactionType",
    "name",
    "timeFrame",
    "postCode",
    "address",
    "orderFrom",
    "currency",
    "promotion",
    "percentagePromotion",
    "coordinates",
    "shippingFee",
    "phone",
    "expectedDeliveryDate",
    "imageAddress",
  ]);
  if (params.products.length === 0) {
    Helper.toastr("Phải có ít nhất 1 sản phẩm trong đơn hàng", "Lỗi!", "error");
    yield put(stopLoading());
  } else {
    const { accessoryData } = yield select((state) => state.billingList);
    const accessories = handleAddAccessoriesToPayload(accessoryData);
    if (accessories.length > 0) {
      params.accessories = accessories;
    }
    const { data } = yield call(
      request,
      "api/fonehouse/order-manager/order",
      params,
      { method: "PUT" }
    );
    yield put(updateBillSuccess(data, meta));
  }
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchBillingManager() {
  yield takeEvery(getBillingListRequest.type, safe(getBillingList, onError));
  yield takeLeading(
    updateBillStatusRequest.type,
    safe(updateBillStatus, onError)
  );
  yield takeLeading(getBillDetailRequest.type, safe(getBillDetail, onError));
  yield takeLeading(createBillRequest.type, safe(createBill, createBillError));
  yield takeLeading(
    updateBillRequest.type,
    safe(updateBill, createBillError, undefined, false)
  );
}
