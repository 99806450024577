import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { Button, Image, Col, List, Row, Typography, Carousel } from "antd";
import LoadingIndicator from "components/LoadingIndicator";
import "./styles.less";
import BackButton from "components/BackButton";
import i18n from "i18n";
import {
  getBillDetailRequest,
  setDiscountDirectModal,
  updateBillStatusRequest,
} from "providers/BillingProvider/slice";
import { useNavigate, useParams } from "react-router";
import TagBillDetail from "components/TagBillDetail";
import TagTypeBillDetail from "components/TagTypeBillDetail";
import TagTransactionType from "components/TagTransactionType";
import moment from "moment";
import { setPopup } from "providers/GeneralProvider/slice";
import DiscountDirectModal from "components/Bill/DiscountDirectModal";
import { createConversationRequest } from "providers/MessengerProvider/slice";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import _isEmpty from "lodash/isEmpty";
import { PlaceHolderIMG } from "assets/images";

const BillDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idBill } = useParams();
  const isLoading = useSelector((state) =>
    _get(state, "billingList.isLoading")
  );
  const billDetail = useSelector((state) =>
    _get(state, "billingList.billDetail")
  );
  const productData = useSelector((state) =>
    _get(state, "billingList.productData")
  );
  const isCreatingConversation = useSelector((state) =>
    _get(state, "messenger.isCreatingConversation")
  );
  const userDetail = _get(billDetail, "user") || _get(billDetail, "admin");
  const userId = _get(userDetail, "_id");
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setOpen] = useState("");
  const totalProfit = useMemo(() => {
    console.log(billDetail);
    const toalProductProfit =
      billDetail?.promotionPrice -
      billDetail?.initialPrice +
      billDetail?.shippingFee;
    let totalAccessoriesProfit = 0;
    billDetail?.accessories?.forEach((accessory) => {
      totalAccessoriesProfit +=
        accessory?.price * accessory?.amount -
        accessory?.initialPrice * accessory?.amount;
    });
    return toalProductProfit + totalAccessoriesProfit - billDetail?.vcoinToYen;
  }, [
    billDetail?.accessories,
    billDetail?.initialPrice,
    billDetail?.promotionPrice,
    billDetail?.shippingFee,
    billDetail?.vcoinToYen,
  ]);

  useEffect(() => {
    dispatch(getBillDetailRequest({ orderId: idBill }));
  }, [dispatch, idBill]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  const handleOpenModal = () => {
    dispatch(
      setDiscountDirectModal({
        visible: true,
      })
    );
  };
  const handleUpdateOrder = () => {
    navigate(`/fonehouse/update_bill/${idBill}`, {
      state: { canGoBack: true },
      replace: true,
    });
  };

  const handleUpdateProduct = (productId) => {
    navigate(`/fonehouse/products/${productId}`, {
      state: { canGoBack: true },
    });
  };

  const handleCreateConversation = () => {
    dispatch(createConversationRequest({ receiverId: userId })).then(
      (result) => {
        navigate(`/fonehouse/messengers/${result._id}`);
      }
    );
  };
  const handleCancelOrder = () => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xác nhận huỷ đơn hàng",
          content: 'Xác nhận trạng thái "Đã huỷ" cho đơn hàng này',
          onOk: () =>
            dispatch(
              updateBillStatusRequest({
                orderId: idBill,
                status: "cancel",
              })
            ).then(() => dispatch(getBillDetailRequest({ orderId: idBill }))),
        },
      })
    );
  };

  const handleSuccessOrder = () => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xác nhận trạng thái",
          content: 'Xác nhận trạng thái "Đã giao" cho đơn hàng này',
          onOk: () =>
            dispatch(
              updateBillStatusRequest({
                orderId: idBill,
                status: "success",
              })
            ).then(() => dispatch(getBillDetailRequest({ orderId: idBill }))),
        },
      })
    );
  };

  const handleShippedOrder = () => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xác nhận trạng thái",
          content: 'Xác nhận trạng thái "Đã gửi hàng" cho đơn hàng này',
          onOk: () =>
            dispatch(
              updateBillStatusRequest({
                orderId: idBill,
                status: "shipped",
              })
            ).then(() => dispatch(getBillDetailRequest({ orderId: idBill }))),
        },
      })
    );
  };

  // eslint-disable-next-line consistent-return
  const renderButtonView = (billStatus) => {
    switch (billStatus) {
      case "cancel":
        return (
          <div className="detail-wrapper">
            <Row justify="space-between">
              <Button
                onClick={handleCreateConversation}
                loading={isCreatingConversation}
                size="small"
                color="primary"
              >
                {i18n.t("billingDetail.message")}
              </Button>
            </Row>
            <Button
              onClick={handleOpenModal}
              type="primary"
              style={{ marginTop: 10 }}
              size="small"
              color="primary"
            >
              {i18n.t("billingDetail.discountDirect")}
            </Button>
          </div>
        );
      case "new":
        return (
          <div className="detail-wrapper">
            <Row justify="space-between">
              <Button
                size="small"
                color="primary"
                style={{ width: 100 }}
                onClick={handleCreateConversation}
                loading={isCreatingConversation}
              >
                {i18n.t("billingDetail.message")}
              </Button>
              <Button
                onClick={() => handleShippedOrder()}
                size="small"
                color="primary"
                style={{ width: 100 }}
              >
                {i18n.t("billingDetail.sendProduct")}
              </Button>
              <Button
                onClick={() => handleCancelOrder()}
                danger
                size="small"
                color="primary"
                style={{ width: 150 }}
              >
                {i18n.t("billingDetail.cancelOrder")}
              </Button>
            </Row>
            <Button
              onClick={handleOpenModal}
              type="primary"
              style={{ marginTop: 10 }}
              size="small"
            >
              {i18n.t("billingDetail.discountDirect")}
            </Button>
          </div>
        );
      case "success":
        return (
          <div className="detail-wrapper">
            <Row justify="space-between">
              <Button
                onClick={handleCreateConversation}
                loading={isCreatingConversation}
                size="small"
                color="primary"
              >
                {i18n.t("billingDetail.message")}
              </Button>
            </Row>
          </div>
        );
      case "shipped":
        return (
          <div className="detail-wrapper">
            <Row justify="space-between">
              <Button
                onClick={handleCreateConversation}
                loading={isCreatingConversation}
                size="small"
                color="primary"
                style={{ width: 100 }}
              >
                {i18n.t("billingDetail.message")}
              </Button>
              <Button
                onClick={() => handleSuccessOrder()}
                size="small"
                color="primary"
                style={{ width: 100 }}
              >
                {i18n.t("billingDetail.alreadySend")}
              </Button>
              <Button
                onClick={() => handleCancelOrder()}
                danger
                size="small"
                color="primary"
                style={{ width: 150 }}
              >
                {i18n.t("billingDetail.cancelOrder")}
              </Button>
            </Row>
            <Button
              onClick={handleOpenModal}
              type="primary"
              style={{ marginTop: 10 }}
              size="small"
              color="primary"
            >
              {i18n.t("billingDetail.discountDirect")}
            </Button>
          </div>
        );
      default:
        break;
    }
  };
  const renderProductItem = (item) => (
    <Row style={{ marginTop: 20, marginBottom: 20 }}>
      <Row gutter={16}>
        <Col>
          <Button
            onClick={() => handleUpdateProduct(item?._id)}
            size="small"
            color="primary"
            style={{ marginBottom: 10 }}
          >
            {i18n.t("billingDetail.editProduct")}
          </Button>
          <Carousel autoplay style={{ width: 150, height: 150 }} arrows>
            {(item?.images || []).map((image, idx) => (
              <Image
                onClick={() => {
                  setOpen(true);
                  setPhotoIndex(idx);
                }}
                fallback={PlaceHolderIMG}
                key={image}
                width={150}
                height={150}
                src={image}
              />
            ))}
          </Carousel>
          {item?.imageMachine && (
            <>
              <div style={{ marginTop: 5 }} className="text-sub-title">
                {i18n.t("billingDetail.imageMachine")}
              </div>
              <Image
                onClick={() => {
                  setOpen(true);
                }}
                fallback={PlaceHolderIMG}
                width={150}
                height={150}
                src={item?.imageMachine}
              />
            </>
          )}
          {isOpen === item?._id && !_isEmpty(item?.images) && (
            <Lightbox
              mainSrc={item?.images[photoIndex]}
              nextSrc={item?.images[(photoIndex + 1) % item?.images.length]}
              prevSrc={
                item?.images[
                  (photoIndex + item?.images.length - 1) % item?.images.length
                ]
              }
              onCloseRequest={() => setOpen(false)}
              onMovePrevRequest={() => {
                setPhotoIndex(
                  (photoIndex + item?.images.length - 1) % item?.images.length
                );
              }}
              onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % item?.images.length);
              }}
            />
          )}
        </Col>
        <Col span={6}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.productCode")}:
            </div>
            <div className="text-sub-content">{item?.productId}</div>
          </Row>

          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.imei")}:
            </div>
            <div className="text-sub-content">{item?.imei}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.productName")}:
            </div>
            <div className="text-sub-content">{item?.name}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.accessories")}:
            </div>
            <div className="text-sub-content">{item?.accessories}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.amount")}:
            </div>
            <div className="text-sub-content">{item?.amount}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.promotion")}:
            </div>
            <div className="text-sub-content">
              {`${(
                (item?.price * billDetail?.percentagePromotion) /
                100
              )?.toLocaleString()} ${billDetail.currency}`}
            </div>
          </Row>
          <Row align="middle" style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.priceSale")}:
            </div>
            <div className="text-product-price">
              {item?.promotionPrice?.toLocaleString()} {billDetail?.currency}
            </div>
          </Row>
        </Col>
        <div
          style={{
            height: "100%",
            width: 1,
            backgroundColor: "#909090",
            marginLeft: 5,
            marginRight: 5,
          }}
        ></div>
        <Col span={"100%"}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.createAt")}:
            </div>
            <div className="text-sub-content">
              {moment(item?.createdAt).format("DD/MM/YYYY")}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.manufacture")}:
            </div>
            <div className="text-sub-content">{item?.manufacture}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.type")}:
            </div>
            <div className="text-sub-content">{item?.state}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.memory")}:
            </div>
            <div className="text-sub-content">{item?.ram}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.inputPrice")}:
            </div>
            <div className="text-input-price">
              {item?.initialPrice?.toLocaleString()} {billDetail?.currency}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.initialPrice")}:
            </div>
            <div className="text-input-price">
              {item?.price?.toLocaleString()} {billDetail?.currency}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.extraPrice")}:
            </div>
            <div
              style={{
                color:
                  item.promotionPrice - item.inputPrice < 0 ? "red" : "green",
              }}
              className="text-extra-price"
            >
              {(item?.promotionPrice - item?.initialPrice)?.toLocaleString()}{" "}
              {item.currency}
            </div>
          </Row>
        </Col>
      </Row>
    </Row>
  );

  const renderAccessoryItem = (accessory) => (
    <Row style={{ marginTop: 20, marginBottom: 20 }}>
      <Row gutter={16}>
        <Col>
          <Carousel autoplay style={{ width: 150, height: 150 }} arrows>
            {(accessory.images || []).map((image, idx) => (
              <Image
                onClick={() => {
                  setOpen(true);
                  setPhotoIndex(idx);
                }}
                fallback={PlaceHolderIMG}
                key={image}
                width={150}
                height={150}
                src={image}
              />
            ))}
          </Carousel>
          <Image
            onClick={() => {
              setOpen(true);
            }}
            fallback={PlaceHolderIMG}
            width={150}
            height={150}
            src={accessory.thumbnail}
          />
        </Col>
        <Col span={6}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.productName")}:
            </div>
            <div className="text-sub-content">{accessory.name}</div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.quantityChoose")}:
            </div>
            <div className="text-sub-content">{accessory.amount}</div>
          </Row>
          <Row align="middle" style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.priceSale")}:
            </div>
            <div className="text-product-price">
              {accessory.initialPrice?.toLocaleString()} JPY
            </div>
          </Row>
        </Col>
        <div
          style={{
            height: "100%",
            width: 1,
            backgroundColor: "#909090",
            marginLeft: 5,
            marginRight: 5,
          }}
        ></div>
        <Col span={10}>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.createAt")}:
            </div>
            <div className="text-sub-content">
              {moment(accessory.createdAt).format("DD/MM/YYYY")}
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.inputPrice")}:
            </div>
            <div className="text-input-price">
              {accessory.initialPrice?.toLocaleString()} JPY
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.initialPrice")}:
            </div>
            <div className="text-input-price">
              {accessory.price?.toLocaleString()} JPY
            </div>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.extraPrice")}:
            </div>
            <div
              style={{
                color:
                  accessory.price - accessory.initialPrice < 0
                    ? "red"
                    : "green",
              }}
              className="text-extra-price"
            >
              {(accessory.price - accessory.initialPrice)?.toLocaleString()} JPY
            </div>
          </Row>
        </Col>
      </Row>
    </Row>
  );

  return (
    <div className="bill-detail-wrapper">
      <DiscountDirectModal setDiscountDirectModal={setDiscountDirectModal} />
      <Row
        className="bill-detail-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>
            {i18n.t("billingDetail.title")}
          </Typography.Title>
          <TagBillDetail status={billDetail.status} />
          <TagTypeBillDetail status={billDetail.orderFrom} />
        </Row>
      </Row>
      <Row>
        <Col span={10}>
          {Object.values(billDetail).length > 0 && (
            <div className="detail-wrapper">
              <Row justify="space-between">
                <div className="text-title">
                  {i18n.t("billingDetail.basicInformation")}
                </div>
                <Row>
                  <TagTransactionType status={billDetail.transactionType} />
                </Row>
              </Row>
              <Row style={{ padding: "5px 0px" }}>
                <div className="text-sub-title">
                  {i18n.t("billingDetail.orderCustomId")}:
                </div>
                <div className="text-sub-content">
                  {_get(billDetail, "orderIdCustom", "")}
                </div>
              </Row>
              <Row style={{ padding: "5px 0px" }}>
                <div className="text-sub-title">
                  {i18n.t("billingDetail.customerName")}
                </div>
                <div className="text-sub-content">
                  {_get(billDetail, "name", "")}
                </div>
              </Row>
              <Row style={{ padding: "5px 0px" }}>
                <div className="text-sub-title">
                  {i18n.t("billingDetail.phone")}
                </div>
                <div className="text-sub-content">
                  {_get(billDetail, "phone", "")}
                </div>
              </Row>
              <Row style={{ padding: "5px 0px" }}>
                <div className="text-sub-title">
                  {i18n.t("billingDetail.postalCode")}:
                </div>
                <div className="text-sub-content">
                  {_get(billDetail, "postCode", "")}
                </div>
              </Row>
              <Row style={{ padding: "5px 0px" }}>
                <div className="text-sub-title">
                  {i18n.t("billingDetail.address")}
                </div>
                <div className="text-sub-content">
                  {_get(billDetail, "address", "")}
                </div>
              </Row>
              <Row style={{ padding: "5px 0px" }}>
                <div className="text-sub-title">Ngày nhận hàng:</div>
                <div className="text-sub-content">
                  {billDetail?.expectedDeliveryDate}
                </div>
              </Row>
              <Row style={{ padding: "5px 0px" }}>
                <div className="text-sub-title">
                  {`${i18n.t("billingDetail.timeProduct")}:`}
                </div>
                <div className="text-sub-content">{billDetail.timeFrame}</div>
              </Row>
              {billDetail?.imageAddress && (
                <Row style={{ padding: "5px 0px" }}>
                  <div className="text-sub-title">Ảnh địa chỉ:</div>
                  <div className="text-sub-content">
                    <Image
                      style={{ width: 100, height: 100 }}
                      src={billDetail?.imageAddress}
                    />
                  </div>
                </Row>
              )}
              <div className="line"></div>
              <div className="text-title">
                {i18n.t("billingDetail.billDetail")}
              </div>
              <Row style={{ padding: "5px 0px" }} justify="space-between">
                <div className="text-sub-title">
                  {i18n.t("billingDetail.productPrice")}
                </div>
                <div className="text-sub-content">
                  {billDetail?.promotionPrice?.toLocaleString()}{" "}
                  {billDetail.currency}
                </div>
              </Row>
              <Row style={{ padding: "5px 0px" }} justify="space-between">
                <div className="text-sub-title">
                  {i18n.t("billingDetail.discount")}
                </div>
                <div className="text-sub-content">
                  {billDetail?.promotion === 0
                    ? `${billDetail.percentagePromotion}%`
                    : `${billDetail?.promotion?.toLocaleString()} ${
                        billDetail.currency
                      }`}
                </div>
              </Row>
              {billDetail?.shippingFee ? (
                <Row style={{ padding: "5px 0px" }} justify="space-between">
                  <div className="text-sub-title">
                    {i18n.t("billingDetail.shippingFee")}
                  </div>
                  <div className="text-sub-content">
                    {billDetail?.shippingFee?.toLocaleString()}{" "}
                    {billDetail.currency}
                  </div>
                </Row>
              ) : (
                <div />
              )}
              {billDetail?.accessories?.map((accessory) => (
                <Row
                  key={accessory?._id}
                  style={{ padding: "5px 0px" }}
                  justify="space-between"
                >
                  <div className="text-sub-title">
                    {`${accessory?.name} x ${accessory?.amount}`}
                  </div>
                  <div className="text-sub-content">
                    {(accessory?.price * accessory?.amount)?.toLocaleString()}{" "}
                    {billDetail.currency}
                  </div>
                </Row>
              ))}
              {billDetail?.vcoinAmount && billDetail?.vcoinToYen && (
                <Row style={{ padding: "5px 0px" }} justify="space-between">
                  <div className="text-sub-title">
                    {`${i18n.t("billingDetail.pointToMoney")} (${
                      billDetail?.vcoinAmount
                    })`}
                  </div>
                  <div className="text-sub-content">
                    {billDetail?.vcoinToYen?.toLocaleString()}{" "}
                    {billDetail.currency}
                  </div>
                </Row>
              )}
              <div className="line"></div>
              <Row justify="space-between">
                <div className="container-center">
                  <div className="text-sub-title-2">
                    {i18n.t("billingDetail.total")}
                  </div>
                  <div className="text-price">
                    {billDetail?.totalPrice?.toLocaleString()}{" "}
                    {billDetail.currency}{" "}
                  </div>
                </div>
                <div className="container-center">
                  <div className="text-sub-title-2">
                    {i18n.t("billingDetail.totalExtra")}
                  </div>
                  <div
                    style={{
                      color:
                        billDetail.promotionPrice - billDetail.initialPrice < 0
                          ? "red"
                          : "green",
                    }}
                    className="text-product-price top10"
                  >
                    {totalProfit?.toLocaleString()} {billDetail.currency}{" "}
                  </div>
                </div>
              </Row>
            </div>
          )}
          {renderButtonView(billDetail.status)}
        </Col>

        <Col span={14}>
          <div className="detail-wrapper">
            <Row justify="space-between">
              <div className="text-title">
                {i18n.t("billingDetail.billDetail")}
              </div>
              <Button
                onClick={handleUpdateOrder}
                size="small"
                color="primary"
                style={{ width: 100 }}
              >
                {i18n.t("billingDetail.edit")}
              </Button>
            </Row>
            <List
              itemLayout="vertical"
              pagination={{
                pageSize: 4,
              }}
              dataSource={productData}
              renderItem={(item) => renderProductItem(item)}
            />
          </div>

          {billDetail?.accessories?.length > 0 && (
            <div className="detail-wrapper">
              <Row justify="space-between">
                <div className="text-title">
                  {i18n.t("billingDetail.accessoryDetail")}
                </div>
              </Row>
              <List
                itemLayout="vertical"
                pagination={{
                  pageSize: 4,
                }}
                dataSource={billDetail.accessories}
                renderItem={(item) => renderAccessoryItem(item)}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BillDetail;
